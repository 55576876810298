<template>
  <b-modal
    :visible="visible"
    :title="isEditingPost ? $t('posts.post-creation.edit-post') : $t('posts.post-creation.create-post')"
    :footer-class="{ 'justify-content-between': !isAddingAttachment }"
    size="lg"
    centered
    @change="$emit('change', $event)"
    @show="handleModalShow"
    @shown="handleModalShown"
    @hidden="handleModalHidden"
  >
    <!-- Normal content -->
    <template v-if="!isAddingAttachment">
      <div>
        <user-avatar size="32" variant="light-primary" :user="loggedMember" />
        <span class="ml-50 font-weight-bolder">
          {{ loggedMember.name }} {{ loggedMember.surname }}
        </span>
      </div>
      <!-- Appending a preview -->
      <append-preview
        :content="content"
        :enabled="isPreviewEnabled"
        cancelable
        @link-found="setHasLinkPreview(true)"
        @cancelled="setHasLinkPreview(false)"
      >
        <quill-editor
          ref="editor"
          v-model="content"
          class="mt-2 post-creation-form__text-editor"
          :placeholder="$t('posts.post-creation.placeholder')"
          :disabled="isPosting"
          @change="handleEditorChange"
        />
      </append-preview>
      <!-- Attachments previews -->
      <div v-if="file.length > 0" 
        class="mw-100 mt-1"
        >
        <div class="text-muted d-none d-lg-block mb-1">
          {{ $t("posts.post-creation.action.attachments-preview") }}
        </div>
        <div class="text-center d-flex flex-wrap">
          <file-preview
            v-for="(item, index) of file"
            :key="index"
            :src="filePath[index]"
            :mime-type="item.type"
            :file-name="item.name"
            style="max-height: 200px; margin-right: 10px;"
            :has-max-height=true
            cancelable
            @cancel="handleRemoveAttachment(index)"
          />
        </div>
      </div>
    </template>
    <!-- When we are adding an attachment... -->
    <div v-else class="text-center mw-100">
      <file-upload
        ref="fileUpload"
        v-model="tempFile"
        :type="attachmentType"
        class="mw-100"
        :placeholder="
          $t('posts.post-creation.file.placeholder', [
            $t(`posts.post-creation.attachments.${attachmentType}`),
          ])
        "
        :drop-placeholder="
          $t('posts.post-creation.file.drop-placeholder', [
            $t(`posts.post-creation.attachments.${attachmentType}`),
          ])
        "
      />
    </div>
    <template v-if="!isAddingAttachment" #modal-footer>
      <div class="w-100">
        <div>
          <span class="text-muted d-inline-block d-lg-none">
            {{ $t("posts.post-creation.action.add-attachment-short") }}
          </span>
          <span class="text-muted d-none d-lg-block">
            {{ $t("posts.post-creation.action.add-attachment-long") }}
          </span>
          <post-creation-attachment-actions
            class="mt-1"
            style="border: 1px solid rgb(238, 238, 238); border-radius: 15px;"
            :disabled="areAttachmentsDisabled"
            @create-post="addAttachment"
          />
        </div>
        <!-- Links preview -->
        <!--div v-if="bodyContainsLinks" class="d-flex flex-wrap justify-content-between">
          <div class="d-flex pt-2">
            <b-form-checkbox v-model="hasLinkPreview" />
              <p>
                {{ $t("posts.post-creation.action.show-links-preview") }}
              </p>
          </div>
        </div-->
        <!-- bottom part -->
        <div class="d-flex flex-wrap justify-content-between">
          <!-- Send notification -->
          <div v-if="canSendNotification && !post" class="d-flex pt-2">
            <div class="d-flex pr-2">
              <b-form-checkbox v-model="sendNotification" />
              <p>
                {{ $t("posts.post-creation.action.email-notification") }} (Staff)
              </p>
            </div>
            <div v-if="sendNotification" class="d-flex">
              <b-form-checkbox v-model="previewHTML" />
              <p>
                {{ $t("posts.post-creation.action.preview-html") }}
              </p>
            </div>
          </div>
          <!-- Action buttons -->
          <div class="mt-1">
            <b-button
              :disabled="isPostCreationDisabled"
              variant="outline-primary"
              class="ml-0 mr-1"
              @click="createPost"
            >
              <template v-if="!post">
                {{ $t("posts.post-creation.action.post") }}
              </template>
              <template v-else>
                {{ $t("posts.post-creation.action.save") }}
              </template>
            </b-button>
            <b-button
              v-if="isEditingPost && canRemovePost"
              :disabled="isPostCreationDisabled"
              variant="outline-danger"
              class="ml-0"
              @click="deletePost"
            >
              {{ $t("action.delete") }}
            </b-button>
          </div>
        </div>
      </div>
    </template>
    <template v-else #modal-footer>
      <b-button variant="outline-secondary" @click="handleCancelAttachment">
        {{ attachment ? $t("action.cancel") : $t("action.go-back") }}
      </b-button>
      <b-button
        variant="outline-primary"
        :disabled="!file"
        @click="handleFileAttachment"
      >
        {{ $t("form.actions.save") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  POSTS_STORE_MODULE_NAME,
} from '@/views/apps/posts/constants/posts-store-constants';
import postsStoreModule from '@/views/apps/posts/store/postsStoreModule';

import { quillEditor } from 'vue-quill-editor';
import PlainEditor from '@core/components/editor/PlainEditor.vue';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { BModal, BButton, BFormCheckbox } from 'bootstrap-vue';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { getImageResource } from '@/@core/utils/image-utils';

import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';

import AppendPreview from '../AppendPreview.vue';
import PostCreationAttachmentActions from './PostCreationAttachmentActions.vue';

export default {
  name: 'PostCreationFormModal',
  components: {
    quillEditor,
    PlainEditor,
    BModal,
    BButton,
    BFormCheckbox,
    UserAvatar,
    AppendPreview,
    PostCreationAttachmentActions,
    FileUpload,
    FilePreview,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    attachment: {
      type: String,
      default: '',
    },

    post: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      content: '',
      file: [],
      tempFile: null,
      hasLinkPreview: false,
      attachmentType: '',
      isAddingAttachment: false,
      isPosting: false,
      isEmpty: true,
      appendPreviewReRender: 0,
      attachmentRemovedFromPost: false,
      sendNotification: false,
      previewHTML: true,
      bodyContainsLinks: true
    };
  },
  computed: {
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    isPostCreationDisabled() {
      return this.isPosting || (this.isEmpty && !this.file);
    },
    areAttachmentsDisabled() {
      return this.isPosting;
    },
    isPreviewEnabled() {
      return !this.file;
    },
    filesToAccept() {
      if (this.attachment === 'image') {
        return 'image/*';
      }
      if (this.attachment === 'video') {
        return 'video/*';
      }
      if (this.attachment === 'document') {
        return (
          '.pdf,.doc,.docx,.odt,.odf,application/msword,'
          + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      }
      return null;
    },
    filePath() {
      const URLs = [];
      for (const row of this.file) {
        URLs.push(URL.createObjectURL(row));
      }
      return URLs;
    },
    isEditingPost() {
      console.log(this.post !== null);
      return this.post !== null;
    },
    postAttachmentURL() {
      if (!this.post) {
        return '';
      }
      if (!this.post.attachments?.length) {
        return '';
      }
      return getImageResource(this.post.attachments[0].filePath);
    },
    canRemovePost() {
      if (this.post?.createdByMember !== undefined) {
        return (
          this.post?.createdByMember?.key
            === this.$store.getters.loggedMember?.key || this.currentCollective.isStaff
        );
      }
      return this.currentCollective.isStaff;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    canSendNotification() {
      return (
        (this.currentCollective.accessibility === 0
          && this.currentCollective.isStaff)
        || (this.currentCollective.accessibility === 1
          && this.currentCollective.isStaff)
        || this.currentCollective.accessibility === 2
      );
    },
  },
  created() {
    if (!this.$store.hasModule(POSTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(POSTS_STORE_MODULE_NAME, postsStoreModule);
    }
  },
  methods: {
    handleModalShow() {
      if (this.attachment) {
        this.addAttachment(this.attachment);
      }
      if (this.post) {
        this.content = this.post.content;
        this.hasLinkPreview = this.post.hasPreview;
        this.attachmentRemovedFromPost = false;
        this.isEmpty = false;
      }
    },
    handleModalShown() {
      if (!this.attachment) {
        // this.$refs.editor.focus();
      }
    },
    handleEditorChange({ text }) {
      this.isEmpty = text.trim().length === 0;
    },
    async addAttachment(attachmentType) {
      this.attachmentType = attachmentType;
      this.isAddingAttachment = true;
      await this.$nextTick();
      if (this.file.length === 0) {
        this.$refs.fileUpload.open();
      }
    },
    setHasLinkPreview(hasLinkPreview) {
      this.hasLinkPreview = hasLinkPreview;
    },
    handleModalHidden() {
      this.file = [];
      this.attachmentType = '';
      this.isAddingAttachment = false;
      this.content = '';
    },
    async createPost() {
      try {
        this.isPosting = true;
        if (!this.post) {
          const file = {};
          for (const row of this.file) {
            file[row.name] = row;
          }
          const response = await this.$store.dispatch('createItem', {
            noSet: true,
            item: {
              itemType: 'posts',
              requestConfig: {
                content: this.content,
                showPreview: this.hasLinkPreview,
                sendThroughEmail: this.sendNotification,
                previewHTML: this.previewHTML
              },
            },
            file,
          });

          this.$socket.emit('newData', {
            itemType: 'posts',
            data: response.data,
            communitySlug: this.$store.getters.currentCollective.slug,
          });
        } else {
          await this.$store.dispatch('editItem', {
            item: {
              itemType: 'modifyPost',
              customName: 'posts',
              requestConfig: {
                postKey: this.post.key,
                content: this.content,
                deleteFile: false,
                showPreview: this.hasLinkPreview,
                sendThroughEmail: this.sendNotification,
                previewHTML: this.previewHTML
              },
            },
            forceUpdate: true,
            file: this.file.length !== 0 ? this.file : undefined,
          });
          this.$emit('posts-updated');
        }

        this.isPosting = false;
        this.$emit('change', false);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('posts.post-creation.success-message'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        });
      } catch (e) {
        console.log(e);
        this.isPosting = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async deletePost() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        this.$t('posts.post-delete.confirmation-message'),
        {
          okVariant: 'danger',
          okTitle: this.$t('action.delete'),
          cancelTitle: this.$t('action.cancel'),
          centered: true,
        },
      );
      if (!isConfirmed) {
        return;
      }

      try {
        // await this.$store.dispatch(POSTS_ACTIONS.deletePost, { postKey: this.post.key });
        await this.$store.dispatch('postItem', {
          type: 'deletePost',
          customName: 'posts',
          forceUpdate: true,
          requestConfig: {
            postKey: this.post.key,
          },
        });
        this.isPosting = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('posts.post-delete.success-message'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        });
        await this.$store.dispatch('getItems', {
          itemType: 'posts',
          perPage: 1000,
          forceAPICall: true,
          page: 1,
          requestConfig: {
            orderByDate: -1,
          },
        });
        this.$emit('change', false);
      } catch {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('posts.post-delete.error-message'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async handleCancelAttachment() {
      if (this.attachment) {
        this.$emit('change', false);
      } else {
        this.attachmentType = '';
        this.tempFile = null;
        this.isAddingAttachment = false;
        await this.$nextTick();
        // this.$refs.editor.focus();
      }
    },
    async handleFileAttachment() {
      this.isAddingAttachment = false;
      if (this.tempFile != null && !this.file.includes(this.tempFile)) {
        this.file.push(this.tempFile);
        this.tempFile = null;
      }
      await this.$nextTick();
      // this.$refs.editor.focus();
    },
    async handleRemoveAttachment(index) {
      this.file.splice(index);
      await this.$nextTick();
      // this.$refs.editor.focus();
    },
    async handleRemoveAttachmentFromPost() {
      this.attachmentRemovedFromPost = true;
      await this.$nextTick();
      // this.$refs.editor.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

.post-creation-form {
  &__text-editor::v-deep {
    line-height: $line-height-base;
    .ql-editor {
      min-height: 96px;
    }
  }
}
.post-creation-form-upload {
  text-align: center;
  &__file::v-deep {
    width: auto;
    .custom-file-label {
      cursor: pointer;
      text-align: center;
      height: auto !important;
      @include button-outline-variant($primary);
      &::after {
        display: none;
      }
    }
  }
}
</style>
