import Service from '@/config/service-identifiers';
import store from '@/store';

/**
 * Posts Store Module
 */
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /**
     * Creates a new post
     * @returns The created post
     */
    async createPost(ctx, {
      file, content, showPreview, sendThroughEmail,
    }) {
      const requestData = {
        communityKey: ctx.rootGetters.currentCollective.key,
        title: '',
        content,
        showPreview,
        sendThroughEmail,
      };

      let response;
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('data', JSON.stringify(requestData));

        response = await store.$service[Service.BackendClient].post(
          'createPost',
          formData,
          { headers: { 'content-type': 'multipart/form-data' } },
        );
      } else {
        response = await store.$service[Service.BackendClient].post('createPost', requestData);
      }

      return response.data;
    },
    async modifyPost(ctx, {
      postKey, file, content, showPreview, deleteFile,
    }) {
      console.log("Ejecuta")
      const requestData = {
        communityKey: ctx.rootGetters.currentCollective.key,
        postKey,
        title: '',
        content,
        showPreview,
        deleteFile: false,
      };
      let response;
      console.log(file)
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('data', JSON.stringify(requestData));
        response = await store.$service[Service.BackendClient].post(
          'modifyPost',
          formData,
          { headers: { 'content-type': 'multipart/form-data' } },
        );
      } else {
        response = await store.$service[Service.BackendClient].post(
          'modifyPost',
          requestData,
        );
      }

      return response.data;
    },
    async deletePost(ctx, { postKey }) {
      return store.$service[Service.BackendClient].post('deletePost', { postKey });
    },
  },
};
